
.landpageImage {
  height: 100%;
  background-image: url('/landingimage.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.maintext {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size:45pt;
  color:darkgreen;
  background-color: lightcyan;
  font-weight: bolder;
  font-style: normal;
  
}